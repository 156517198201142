.featurebox{
    border: 0px solid black;
    width: 100%;
    height: 100vh;
    background-color: #010313;
    position: relative;
    color: white;
}
#innerFeatureBox{
    border: 0px solid white;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    width: 99%;
    margin:auto;
    padding-top: 50px;
}
#featuresDiv{
    border: 1px solid #1b262c;
    width: 485px;
    height: 571px;
    border-radius:20px;
    display: grid;
    gap: 10px;
}
#featuresDiv:hover{
    background-image: url(../images/featurebg.jpg);
    background-size: 100% 100%;
    animation: fadeIn 0.8s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
#featuresVideo{
    border: 0px solid yellow;
    width: 50%;
}
.featuresVideo{
    width: 100%;
}
#featuresImage{

    display: flex;
    justify-content: center;
}
#featuresText{
    border: 0px solid white;
    width: 90%;
    margin: auto;
    font-family: Raleway, sans-serif;
    font-size:19.1877px;
    line-height: 30.3166px;
    text-align: start;
    letter-spacing: normal;
    font-weight: lighter;
}
/* for Nest hub max and nest hub */
@media (max-width:1300px) and (min-width:1020px){
    .featurebox{
        border: 0px solid white;
        width: 100%;
        height:fit-content;
        background-color: #010313;
        position: relative;
        color: white;
        padding-bottom: 50px;
    }
    #innerFeatureBox{
        grid-template-columns: repeat(2,1fr);
        margin-top: 0;
        gap: 20px;
    }
    #featuresDiv{
        width: 90%;
        margin:auto
    }
}
@media (max-width:920px){
    .featurebox{
        border: 0px solid white;
        width: 100%;
        height:fit-content;
        background-color: #010313;
        position: relative;
        color: white;
        padding-bottom: 50px;
    }
    #innerFeatureBox{
        grid-template-columns: repeat(1,1fr);
        margin-top: 0;
        gap: 20px;
    }
    #featuresDiv{
        width: 90%;
        margin:auto
    }
}
@media (max-width:820px) {
    .featurebox{
        height: fit-content;
        padding-bottom: 50px;
    }
    #innerFeatureBox{
        grid-template-columns: repeat(1,1fr);
        margin-top: 0;
        gap: 20px;
    }
    #featuresVideo{
        width: 40%;
    }
    #featuresDiv{
        border: 2px solid #1b262c;
        width: 80%;
        height: fit-content;
        margin: auto;
        gap: 5px;
        padding-bottom: 20px;
    }
    #featuresText{
        font-size: 25px;
    }
}
@media (max-width:550px){
    #featuresVideo{
        width: 60%;
    }
    #featuresDiv{
        width: 90%;
    }
    #featuresText{
        font-size: 15px;
    }
}
@media (max-width:480px) and (min-width:100px){
    .featurebox{
        height: fit-content;
    }
    #innerFeatureBox{
        grid-template-columns: repeat(1,1fr);
        margin-top: 0;
    }
    #featuresVideo{
        width: 60%;
    }
    #featuresDiv{
        width: 95%;
        height: 400px;
        margin: auto;
        gap: 5px;
    }
    #featuresText{
        font-size: 17px;
    }
}
