#lsdDiv{
    position: relative;
    height: 100vh;
}
#lsdVideo{
    width: 100%;
}
#lsdVideo2{
    display: none;
}
.lsdText{
    border: 0px solid white;
    position: absolute;
    color: white;
    bottom: 0.5em;
    width: 90%;
    left: 3em;
    display: grid;
    gap: 10px;
}
.lsdText1_h2{
  border: 0px solid yellow;
  width: 70%;
  font-family: Roobert, sans-serif;
  color: #fff;
  font-size: 5.7em;
  line-height: 100%;
  font-weight: 400;
  text-transform: none;
  background-image: linear-gradient(88deg, #5e5e70, #c6c6c7 25%, #fff 51%, #73747d);
  line-height: 130%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.lsdText_bottom_div{
    margin: 0;
    width: 80%;
    border-top: 2px solid white;
    display: flex;
    justify-content: space-between;
}
#lsdP_tag1{
  font-size: 18px;
  line-height: 145%;
  font-family: Raleway, sans-serif;
  line-height: 158%;
  text-align: left;
  font-weight:1px;
}
#lsdTextBox1{
    border: 0px solid white;
    width: 40%;
}
#lsdTextBox2{
    width: 50%;
}
/* nest hub*/
@media (max-width:1300px){
    #lsdDiv{
        background-color: black;
    }
  .lsdText{
    border: 0px solid white;
    bottom: 0em;
  }
}
@media (max-width:920px) and (min-width:750px){
    #lsdVideo{
        display: none;
    }
    #lsdVideo2{
        display: block;
        width: 100%;
    }
    .lsdText{
        border: 0px solid white;
        bottom: 0em;
    }

}
@media (max-width:550px){
    #lsdDiv{
        background-color: black;
        height:55em;
        margin: 0;
        padding: 0;
    }
    #lsdVideo{
        display: none;
    }
    #lsdVideo2{
        display: block;
        width: 100%;
    }
    .lsdText{
        border: 0px solid yellow;
        left: 0;
        bottom: 0;
        width: 100%;
        gap: 50px;
        top: 1em;
    }
    .lsdText1_h2{
        border: 0px solid white;
        font-size: 4em;
        margin: auto;
        width: 80%;
    }
    .lsdText_bottom_div{
        display: grid;
        width: 90%;
        margin: auto;
        margin-top: 100px;
    }
    #lsdP_tag1{
        font-size: 15px;
    }
    #lsdTextBox1{
        border: 0px solid white;
        width: 100%;
    }
    #lsdTextBox2{
        width: 100%;
    }
}
@media (max-width:480px) and (min-width:100px){
    #lsdDiv{
        background-color: black;
        height:55em;
        margin: 0;
        padding: 0;
    }
    #lsdVideo{
        display: none;
    }
    #lsdVideo2{
        display: block;
        width: 100%;
    }
    .lsdText{
        border: 0px solid yellow;
        left: 0;
        bottom: 0;
        width: 100%;
        gap: 50px;
        top: 1em;
    }
    .lsdText1_h2{
        border: 0px solid white;
        font-size: 4em;
        margin: auto;
        width: 80%;
    }
    .lsdText_bottom_div{
        display: grid;
        width: 90%;
        margin: auto;
        margin-top: 100px;
    }
    #lsdP_tag1{
        font-size: 15px;
    }
    #lsdTextBox1{
        border: 0px solid white;
        width: 100%;
    }
    #lsdTextBox2{
        width: 100%;
    }
}
@media (max-width:280px){
    .lsdText1_h2{
        font-size: 45px;
    }
    .lsdText{
        gap: 30px;
    }
}