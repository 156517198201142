.evmBox{
    position:relative;
    border: 0px solid white;
    background-color:black;
    background-image:url(../images/background2.png);
    background-size: 100%;
    height: 40em;
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.evmHeading1{
/* position: relative; */
  margin: 0;
  margin-top: 1.5em;
  background-image: linear-gradient(90deg, #5e5e70, #c6c6c7 29%, #fff 55%, #73747d);
  font-family: Roobert, sans-serif;
  font-size: 10.5em;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-transform: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.evmHeading2{
  font-family: Roobert, sans-serif;
  font-size: 2.5em;
  margin: 0;
  line-height: 100%;
  font-weight: 400;
  color: white;
  text-align: center;
}
/* nest hub */
@media (max-width:1300px){
    .evmBox{
        height: 35em;
    }
    .evmHeading1{
        margin-top: 0;
        font-size: 7em;
        text-align: center;
        margin-top: 0.5em;
    }
}
@media (max-width:1030px){
    .evmBox{
        height: 30em;
    }
    .evmHeading1{
        margin-top: 0;
        font-size: 7em;
        text-align: center;
        margin-top: 0.5em;
    }
}
@media (max-width:920px) and (min-width:750px){
    .evmBox{
        background-size: 100% 100%;
        width: 100%;
        left: 0;
        background-image: url(../images/bg3.png);
        background-size: 100% 110%;
        height:60em;
    } 
    .evmHeading1{
        margin-top: 0;
        font-size: 7em;
        text-align: center;
        margin-top: 1.5em;
    }
    .evmHeading2{
        font-size: 2em;
    }
}
@media (max-width:424px){
    .evmBox{
        height: 60vh;
    }
}
@media (max-width:550px) and (min-width:100px){
    .evmBox{
        background-size: 100% 100%;
        width: 100%;
        left: 0;
        background-image: url(../images/bg3.png);
        background-size: 100%;
    }
    .evmHeading1{
        font-size: 5em;
        text-align: center;
        margin-top: 1.5em;
    }
    .evmHeading2{
        border: 0px solid white;
        width: 80%;
        margin: auto;
        line-height: 20px;
        font-size: 1.1em;
        margin-top: 10px;
    }
}
@media (max-width:280px){
    .evmHeading1{
        font-size: 3.5em;
        text-align: center;
        margin-top: 1.5em;
    }
}