#teamBox{
    position: relative;
    background-color: #010313;
    height: fit-content;
    background-image: url(https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c14f7364f80c4dab030d_Frame%201291-p-800.png);
    padding-bottom: 50px;
}
#teamHeading{
    margin: 0;
    letter-spacing: 2px;
    border: 0px solid white;
    width: 95%;
    margin: auto;
    font-family: Roobert, sans-serif;
    color: #fff;
    font-size: 5em;
    line-height: 100%;
    font-weight: 400;
     text-transform: none;
}
#teamHeading2{
    letter-spacing: 2px;
    border: 0px solid white;
    width: 95%;
    margin: auto;
    font-family: Roobert, sans-serif;
    color: #fff;
    font-size: 5em;
    line-height: 100%;
    font-weight: 400;
     text-transform: none;
     margin-top: 60px;
}
/* #scrollContainer{
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    margin: 0 auto 0 auto;
    width: 100%;
    height: fit-content;
    border: 0px solid blue;
    scroll-behavior: smooth;
    color: white;
} */
#scrollContainer{
    width: 100%;
    border: 0px solid yellow;
    z-index: 0;
    margin-top: 50px;
}
.mySwiper{
    border: 0px solid yellow;
    width: 95%;
    margin: auto;
    z-index: inherit;
}
#swiperBox{
    border: 0px solid orange;
    display: block;
    justify-content: center;
    align-items: center;
    z-index: inherit;
}
#teamProfile{
    border: 2px solid #283740;
    width: 55%;
    height: 42em;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 20px;
}
#profileImgBox{
    border: 0px solid blue;
    width: 100%;
}
#profileImage{
    width: 100%;
    height: 24em;
}
#teamProfileBox{
    width: 90%;
    border: 0px solid yellow;
    margin: auto;
}
#teamName{
  font-family: Roobert, sans-serif;
  color: #fcfcfc;
  font-size: 2em;
  line-height: 150%;
  font-weight: 400;
  margin: 0;
}
#teamDesig{
  font-size: 1.2em;
  line-height: 145%;
  margin: 0;
}
#teamDesc{
    font-size: 1.2em;
    line-height: 145%;
    margin-top: 10px;
    font-family: Roobert, sans-serif;
}
#teamBoxMob{
    display: none;
}
#advisorsBox{
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 18px;
    margin-top: 50px;
}
#teamProfile2{
    border: 2px solid #283740;
    height: 42em;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 20px;
}
#profileImage2{
    width: 100%;
    height: 25em;
}
@media (max-width:1300px){
    #scrollContainer{
        display: none;
    }
    #teamBoxMob{
        border: 0px solid white;
        width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
    }
    #teamProfile{
        width: 100%;
        height: 45em;
    }
    #teamProfile2{
        height: 45em;
    }
}
@media (max-width:1030px){
    #scrollContainer{
        display: none;
    }
    #teamBoxMob{
        border: 0px solid white;
        width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
    }
    #teamProfile{
        width: 100%;
        height: 42em;
    }
    #teamProfile2{
        height: 39em;
    }
    #profileImage{
        width: 100%;
        height: 17em;
    }
    #profileImage2{
        width: 100%;
        height: 17em;
    }
}
@media (max-width:920px) and (min-width:750px){
    #scrollContainer{
        display: none;
    }
    #teamHeading{
        font-size: 4em;
    }
    #teamBox{
        height: fit-content;
        padding-top: 50px;
    }
    #teamBoxMob{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    #teamProfile{
        width: 95%;
        margin: auto;
    }
    #profileImage{
        width: 100%;
        height: 20em;
    }
    #advisorsBox{
        grid-template-columns: repeat(2,1fr);
    }
    #profileImage2{
        width: 100%;
        height: 20em;
    }
}
@media (max-width:550px) {
    #scrollContainer{
        display: none;
    }
    #teamHeading{
        font-size: 3em;
    }
    #teamBox{
        height: fit-content;
        top:0rem ;
        padding-top: 50px;
    }
    #teamBoxMob{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    #teamProfile{
        width: 95%;
        margin: auto;
        height: 36em;
    }
    #teamProfile2{
        height: 37em;
    }
    #profileImage{
        width: 100%;
        height: 15em;
    }
    #advisorsBox{
        grid-template-columns: repeat(2,1fr);
    }
    #profileImage2{
        width: 100%;
        height: 15em;
    }
    #teamDesc{
        font-size: 16px;
    }
}
@media (max-width:480px){
    #scrollContainer{
        display: none;
    }
    #teamHeading{
        font-size: 4em;
    }
    #teamBox{
        height: fit-content;
        top:23.81rem ;
    }
    #teamBoxMob{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
    #teamProfile{
        width: 95%;
        margin: auto;
    }
    #profileImage{
        width: 100%;
        height: 20em;
    }
    #advisorsBox{
        grid-template-columns: repeat(1,1fr);
    }
    #profileImage2{
        width: 100%;
        height: 20em;
    }
}
@media (max-width:280px){
    #teamHeading2,#teamHeading{
        font-size: 37px;
    }
    #profileImage{
        width: 100%;
        height: 15em;
    }
    #profileImage2{
        height: 15em;
    }
}