body{
  scroll-behavior: smooth;
}
.bgVideo{
    position: fixed;
  }
  @keyframes fadeIn {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  #homeVideo{
    width: 100%;
    position: relative;
    filter: brightness(0.7);
  }
  #homeVideo2{
    display: none;
  }
  .VideoContent{
    position: absolute;
    border: 0px solid white;
    width: 96%;
    left: 2em;
    /* display: grid; */
    bottom: 9em;
    display: flex;
    justify-content: space-between;
  }
  .homeText{
    border: 0px solid red;
    width: 78%;
    color: #ffffff;
    font-size: 75px;
    text-align: start;
    padding-bottom: 20px;
  }
  .homeText p{
    margin: 0px;
    margin-left: 20px;
    letter-spacing: 2px;
    width: 100%;
    border: 0px solid blue;
  }
  #trillionText{
    background: linear-gradient(to right,#6bcd9a, #4978e6);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
  }
  .SocialMediaLinks{
    border:0px solid blue;
    width: 2%;
    display: grid;
    height:fit-content;
    align-items: center;
    gap: 30px;
    bottom: 20em;
    right: 1em;
    position: absolute;
  }
  .SocialMediaLinks img{
    width: 90%;
    border: 0px solid yellow;
  }
  .introBox{
    position: relative;
    border: 0px solid red;
    height: 100vh;
    visibility: hidden;
    z-index: 1;
  }
  #newIntro{
    background-image: url(../images/background1.png);
    background-size: 100%;
    width: 99%;
    left: 7px;
    border-radius:30px;
    height: 50em;
    position: relative;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #newIntro > div{
    border: 0px solid white;
    width: 80%;
    margin: auto;
    display: grid;
    gap: 10px;
  }
  .text1{
    border: 0px solid white;
    width: 100%;
    font-size: 35px;
    margin: auto;
  }
  .text2{
    border:0px solid red;
    width: 100%;
    font-size: 6em;
  }
  .text2 >p{
    margin: 0px;
  }
  .text3{
    font-size: 19px;
    text-align: center;
    width: 65%;
    margin:auto;
  }
  #introEva{
    border: 0px solid transparent;
    background-color: black;
    position: relative;
    color: white;
    text-align: center;
    font-family: sans-serif;
    font-size: 5em;
    overflow: hidden;
    /* min-height: 20em; */
    /* margin-right: auto; */
    /* margin-bottom: -31em; */
    /* margin-left: auto; */
  }
  #introEva h2{
    /* margin: 0; */
   z-index: 9;
   /* margin-right: -0.1em;
   margin-left: -0.1em; */
   background-image: linear-gradient(90deg, #5e5e70, #c6c6c7 29%, #fff 55%, #73747d);
   font-family: Roobert, sans-serif;
   font-size: 2em;
   font-weight: 400;
   letter-spacing: -0.01em;
   text-transform: none;
   -webkit-background-clip: text;
   background-clip: text;
   -webkit-text-fill-color: transparent;
  }
  .spacing{
    /* position: -webkit-sticky; */
    /* position: sticky; */
    top: 0.2em;
    /* z-index: 1; */
    border: 0px solid white;
    width: 80%;
    margin: auto;
    background-image: linear-gradient(90deg, #434353, #c6c6c7 29%, #fff 55%, #71daad 84%);
    line-height: 122%;
    letter-spacing: -0.02em;
  }
  .evaSpacing{
    background-image: linear-gradient(110deg, #73e0a9, #3f5eff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  @media (max-width:1300px){
    .VideoContent{
      bottom: 1em;
    }
    .SocialMediaLinks{
      width: 3%;
    }
    #newIntro{
      height: 40em;
      width: 100%;
      left: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    #introEva{
      border: 0px solid white;
      font-size: 3em;
    }
  }
  @media (max-width:1030px){
    .VideoContent{
      border: 0px solid white;
      bottom: 1em;
    }
    .homeText p{
        font-size: 55px;
    }
    .SocialMediaLinks{
      border: 0px solid white;
      width: 3%;
      bottom: 12em;
    }
    #newIntro{
      height: 30em;
      width: 100%;
      left: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .text2{
      border:0px solid red;
      width: 100%;
      font-size: 4em;
    }
    #introEva{
      border: 0px solid white;
      font-size: 3em;
    }
  }

  /* tabs */
  @media (max-width:920px){
    #homeVideo{
      display: none;
    }
    #homeVideo2{
      display: block;
      width: 100%;
      position: relative;
    }
    .VideoContent{
      bottom: 25em;
    }
    .SocialMediaLinks{
      width: 6%;
    }
    #newIntro{
      width: 100%;
      background-size:100% 120%;
      left: 0;
      background-image: url(../images/bg2.png);
      border-radius: 20px;
    }
    .text3{
      width: 100%;
      font-size: 20px;
      margin-top: 0px;
    }
    #introEva{
      margin-top: 0;
      font-size: 3em;
      margin-left: 0;
      margin-right: 0;
    }
    .text1{
      border: 0px solid white;
      width: 100%;
      font-size: 35px;
      margin: auto;
    }
    .text2{
      border:0px solid red;
      width: 100%;
      font-size: 6em;
    }
    .text2 >p{
      margin: 0px;
    }
    .text3{
      font-size: 25px;
      text-align: center;
      width: 65%;
      margin:auto;
    }
  }

  @media screen and (max-width:820px) and (min-width:750px){
    #homeVideo{
      display: none;
    }
    #homeVideo2{
      display: block;
      width: 100%;
      position: relative;
    }
    .VideoContent{
      bottom: 25em;
    }
    .SocialMediaLinks{
      width: 6%;
    }
    #newIntro{
      width: 100%;
      background-size:100% 120%;
      left: 0;
      background-image: url(../images/bg2.png);
      border-radius: 20px;
    }
    .text3{
      width: 100%;
      font-size: 30px;
      margin-top: 15px;
    }
    #introEva{
      margin-top: 0;
      font-size: 3em;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width:424px){
    .introBox{
      border: 0px solid yellow;
      height: 80vh;
      visibility: hidden;
    }
    .homeText p{
      font-size:28px;
  }
  }
  @media (max-width:550px){
    #homeVideo{
      display: none;
    }
    #homeVideo2{
      display: block;
      width: 100%;
      position: relative;
    }
    .SocialMediaLinks{
      display: none;
    }
    .VideoContent{
      border: 0px solid yellow;
      left: 0;
      bottom:20em;
    }
    .homeText{
      border: 0px solid white;
      width: 100%;
      font-size: 45px;
      font-weight: 800;
      margin-left: 0;
    }
    .homeText p{
      margin-left: 10px
    }
    #newIntro{
      width: 100%;
      background-size:100% 110%;
      left: 0;
      background-image: url(../images/bg2.png);
      border-radius: 20px;
      height: 500px;
    }
    #newIntro > div{
      width: 100%;
    }
    .text1{
      font-size: 17px;
      margin-bottom: 30px;
    }
    .text2 p{
      border: 0px solid black;
      width: 80%;
      margin: auto;
      font-size: 35px;
    }
    .text3{
      width: 100%;
      font-size: 20px;
      margin-top: 15px;
    }
    #introEva{
      margin-top: 0;
      font-size: 1.5em;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width:480px) and (min-width:100px) {
    #homeVideo{
      display: none;
    }
    #homeVideo2{
      display: block;
      width: 100%;
      position: relative;
    }
    .SocialMediaLinks{
      display: none;
    }
    .VideoContent{
      border: 0px solid yellow;
      left: 0;
      bottom:11em;
    }
    .homeText{
      border: 0px solid white;
      width: 100%;
      font-size: 32px;
      font-weight: bold;
      margin-left: 0;
    }
    .homeText p{
      margin-left: 10px
    }
    #newIntro{
      width: 100%;
      background-size:100% 110%;
      left: 0;
      background-image: url(../images/bg2.png);
      border-radius: 20px;
      height: 500px;
    }
    #newIntro > div{
      width: 100%;
    }
    .text1{
      font-size: 17px;
      margin-bottom: 30px;
    }
    .text2 p{
      border: 0px solid black;
      width: 80%;
      margin: auto;
      font-size: 35px;
    }
    .text3{
      width: 100%;
      font-size: 20px;
      margin-top: 15px;
    }
    #introEva{
      margin-top: 0;
      font-size: 1.5em;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width:280px){
    #introEva{
      font-size: 1em;
    }
    .VideoContent{
      border: 0px solid yellow;
      left: 0;
      bottom:3em;
    }
    .text3{
      width: 100%;
      font-size: 14px;
      margin-top: 15px;
    }
  }
