.nav{
    border: 0px solid white;
    margin-top: 30px;
}
#logo{
    width: 10%;
}
/* Default width for PC devices */
.logoImage {
  width: 270%;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .logoImage {
    width: 60%; /* Set the width to 100% for mobile devices */
  }
}
/* navbar desktop size */
.nav{
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.767); */
    /* position: fixed; */
    position: absolute;
    background-color:transparent;
    overflow: hidden;
    top: 0px;
    z-index: 1;
    border: 0px solid red;
    /* box-shadow: rgba(255, 255, 255, 0.386) 0px 3px 8px; */
}
/* .activeNav {
    border-bottom: 1px solid #ffc400;
    background-color: black;
    box-shadow: #ffc4008d 0px 3px 8px;
} */
#navbar{
    width: 95%;
    margin: auto;
    color: white;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0px solid white;
    padding: 7px;
}
#bar{
    display: none;
}
#nav_menu{
    width: 40%;
    border: 0px solid white;
    height: fit-content;
    display: flex;
    background-color: #454649;
    justify-content: space-around;
    font-family:sans-serif;
    align-items: center;
    border-radius: 40px;
    opacity: 0.9;
}
#nav_menu > p {
    font-weight:100;
    letter-spacing:2px;
    line-height: 15px;
    font-family: Raleway, sans-serif;
    font-size: 17px;
}
#nav_menu > p:hover{
    border: 0px solid white;
    cursor: pointer;
    background: linear-gradient(to right,#6bcd9a, #4978e6);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
#bar{
    color: white;
}

/* styling for mobile nav */
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
  }

  .container {
    max-width:100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  .navbar {
    background-color:#0071dc;
    position: fixed;
    width: 100%;
    z-index: 1;
  }


  .logo img{
     width: 50%;
  }

  .menu-icon {
    display: none;
  }

  .nav-elements {
    display: none;
  }
  .dummyBtn{
    height: 40px;
    width: 20%;
    visibility: hidden;
  }
  .docsBtn{
    height: 35px;
    width: 7%;
    border-radius: 30px;
    border: none;
    font-size: 17px;
    position: fixed;
    right: 54px;
    background-image: linear-gradient(to bottom,#ffffff, #4978e6);
    z-index:1
  }
  /* for Nest hub max and nest hub */
  @media (max-width:1300px){
    #navbar{
      display: flex;
      justify-content: space-between;
      width: 90%;
      border: 0px solid white;
    }
    #logo{
      width: 20%;
    }
    #nav_menu{
      border: 0px solid yellow;
      width: 50%;
      padding-left: 10px;
    }
    .docsBtn{
      width: 10%;
      right: 75px;
    }
  }
  @media (max-width:1030px){
    #navbar{
      display: flex;
      justify-content: space-between;
      width: 95%;
      border: 0px solid white;
    }
    #logo{
      width: 20%;
    }
    #nav_menu{
      border: 0px solid yellow;
      width: 50%;
      padding-left: 10px;
    }
    .docsBtn{
      width: 10%;
      right: 30px;
    }
   }
  @media (max-width:920px){
    #navbar{
      display: flex;
      justify-content: space-around;
      width: 100%;
      border: 0px solid white;
    }
    #logo{
      width: 30%;
    }
    #nav_menu{
      border: 0px solid yellow;
      width: 60%;
      padding-left: 10px;
    }
    .docsBtn{
      width: 10%;
      right: 25px;
    }
  }
/* Tablets */
  @media (max-width:820px){
    #navbar{
      display: flex;
      justify-content: space-around;
      width: 100%;
      border: 0px solid white;
    }
    .menu-icon {
      display: none;
    }
    .nav-elements{
      display: none;
    }
    #dummyBtn{
      border: 0px solid yellow;
      visibility: hidden;
    }
    #logo{
      width: 30%;
    }
    #nav_menu{
      border: 0px solid yellow;
      width: 60%;
      padding-left: 10px;
    }
    .docsBtn{
      width: 10%;
      right: 25px;
    }
  }

  /* Surfave Duo */
  @media (max-width:550px){
    #navbar{
      width: 95%;
      background-color: transparent;
      justify-content: space-between;
  }
  #logo{
      width: 40%;
  }
  #closeBtn{
    border: 0px solid white;
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 30px;
    width: 90%;
    margin: auto;
    margin-top: 40px;
  }
  .container{
      padding: 10px;
  }
  #nav_menu{
      display: none;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    color: white;
    font-size: 25px;
    z-index:initial;
  }

  .nav-elements {
    border: 0px solid white;
    display:block;
    position: absolute;
    right: 0;
    top: 0px;
    background-color: black;
    width: 0px;
    height: 100vh;
    transition: all 0.2s ease-in;
    overflow: hidden;
    z-index: 1;
    color: white;
    background-image: url(https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fc1396c1ef8e856068a502_mobile.webp);
  }
  .nav-elements.active {
    border: 0px solid white;
    width: 100%;
    height: 100vh;
    box-shadow: none;
  }
  .active p{
      border: 0px solid white;
      width: 100%;
      margin: auto;
      margin: 0;
      font-family: sans-serif;
  }
  #menuMob{
    width: 80%;
    border: 0px solid white;
    margin: auto;
    margin-top: 40px;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
  }
  #menuBox{
    border: 0px solid black;
    display: flex;
    width: 100%;
    gap: 0px;
  }
  #MenuNums{
    width: 10%;
    border: 0px solid yellow;
    font-size: 12px;
    margin: 0;
  }
  #menuText{
    border: 0px solid blue;
    display: block;
    justify-content: flex-start;
    margin-right: 30px;
  }
  .socialIcons{
    width: 120%;
    margin: auto;
    border: 0px solid white;
    display: flex;
    margin-top: 60px;
    justify-content: space-around;
  }
  .socialIcons img{
    width: 52%;
  }
  #docsBtn2{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 50px;
  }
  .docsBtn2{
    height: 50px;
    width: 100%;
    border-radius: 30px;
    border: none;
    font-size: 18px;
    right: 54px;
    background-image: linear-gradient(to right,#6bcd9a, #4978e6);
    font-weight: bold;
  }
  }

  /* Mobile screen */
  @media (max-width: 480px) and (min-width : 100px) {
    #navbar{
        background-color: transparent;
    }
    #logo{
        width: 40%;
    }
    #closeBtn{
      border: 0px solid white;
      width: 100%;
      display: flex;
      justify-content: end;
      font-size: 30px;
      width: 90%;
      margin: auto;
      margin-top: 40px;
    }
    .container{
        padding: 10px;
    }
    #nav_menu{
        display: none;
    }
    .menu-icon {
      display: block;
      cursor: pointer;
      color: white;
      font-size: 25px;
      z-index:initial;
    }

    .nav-elements {
      border: 0px solid white;
      display:block;
      position: absolute;
      right: 0;
      top: 0px;
      background-color: black;
      width: 0px;
      height: 100vh;
      transition: all 0.2s ease-in;
      overflow: hidden;
      z-index: 1;
      color: white;
      background-image: url(https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fc1396c1ef8e856068a502_mobile.webp);
    }
    .nav-elements.active {
      border: 0px solid white;
      width: 100%;
      height: 100vh;
      box-shadow: none;
    }
    .active p{
        border: 0px solid white;
        width: 100%;
        margin: auto;
        margin: 0;
        font-family: sans-serif;
    }
    #menuMob{
      width: 80%;
      border: 0px solid white;
      margin: auto;
      margin-top: 40px;
      font-size: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 30px;
    }
    #menuBox{
      border: 0px solid black;
      display: flex;
      width: 100%;
      gap: 0px;
    }
    #MenuNums{
      width: 10%;
      border: 0px solid yellow;
      font-size: 12px;
      margin: 0;
    }
    #menuText{
      border: 0px solid blue;
      display: block;
      justify-content: flex-start;
      margin-right: 30px;
    }
    .socialIcons{
      width: 80%;
      margin: auto;
      border: 0px solid white;
      display: flex;
      margin-top: 60px;
      justify-content: space-around;
    }
    .socialIcons img{
      width: 52%;
    }
    #docsBtn2{
      width: 80%;
      margin: auto;
      text-align: center;
      margin-top: 50px;
    }
    .docsBtn2{
      height: 50px;
      width: 100%;
      border-radius: 30px;
      border: none;
      font-size: 18px;
      right: 54px;
      background-image: linear-gradient(to right,#6bcd9a, #4978e6);
      font-weight: bold;
    }
    /* .nav-elements ul {
      display: flex;
      flex-direction: column;
    } */

    /* .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    } */
  }

