#footerBox{
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #010313;
    display: grid;
    gap: 50px;
    padding-bottom: 100px;
}
.topBox{
    border: 0px solid white;
    color: white;
    padding-top: 100px;
}
#footerH1{
  flex: 0 0 auto;
  font-family: Roobert, sans-serif;
  color: #fff;
  font-size: 7em;
  line-height: 100%;
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-transform: none;
  margin: 0;
}
.botBox{
    border: 0px solid white;
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    color: white;
    height: 227px;
}
#botBox1{
    width: 45%;
    border: 0px solid yellow;
    background-color: #5a67dc;
    border-radius: 20px;
}
#botBox2{
    width: 45%;
    border: 0px solid yellow;
    background-color: #73dfa9;
    border-radius: 20px;
    color: black;
}
#arrowIcon{
    border: 0px solid black;
    display:flex;
    width: 90%;
    margin: auto;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 40px;
}
#botText2{
    border: 0px solid black;
    width: 90%;
    margin: auto;
    margin-top: 90px;
    font-size: 12.6598px;
    font-family: Ttfirsneue, sans-serif;

}
#botText1{
    border: 0px solid black;
    width: 90%;
    margin: auto;
    margin-top: 110px;
    font-size: 25.6598px;
    font-family: Ttfirsneue, sans-serif;    
}
#botText2 h1{
    margin: 0;
}
#botBox1:hover{
    color: black;
    background-image: linear-gradient(to right,#6bcd9a, #4978e6);
    cursor: pointer;
}
#botBox2:hover{
    color: white;
    background-image: linear-gradient(to right,#4978e6,#6bcd9a);
    cursor: pointer;
}
@media (max-width:920px) and (min-width:750px){
    .botBox{
        display:grid ;
        width: 100%;
        display: grid;
        gap: 20px;
        height: fit-content;
    }
    #botBox1{
        border: 0px solid white;
        width:545px;
        height: 250px;
        border-radius:0px;
    }
    #botBox2{
        width:545px;
        height: 250px;
        border-radius:0px;
    }
}
@media (max-width:550px){
    #footerH1{
        font-size: 50px;
        text-align: left;
        margin-left: 20px;
    }
    .botBox{
        border: 0px solid yellow;
        width: 100%;
        display: grid;
        gap: 20px;
        height: fit-content;
    }
    #botBox1{
        border: 0px solid white;
        width:343px;
        height: 180px;
        border-radius:0px;
    }
    #botBox2{
        width:343px;
        height: 180px;
        border-radius:0px;
    }
    #botText1{
        margin-top: 60px;
        font-size: 25px;
    }
    #botText2{
        margin-top: 50px;
        font-size: 12px;
    }
}
@media (max-width:480px) and (min-width:100px){
    #footerBox{
        border: 0px solid white;
        width: 100%;
        margin-top: 23.9em;
    }
    #footerH1{
        font-size: 50px;
        text-align: left;
        margin-left: 20px;
    }
    .botBox{
        border: 0px solid yellow;
        width: 100%;
        display: grid;
        gap: 20px;
        height: fit-content;
    }
    #botBox1{
        border: 0px solid white;
        width:343px;
        height: 180px;
        border-radius:0px;
    }
    #botBox2{
        width:343px;
        height: 180px;
        border-radius:0px;
    }
    #botText1{
        margin-top: 60px;
        font-size: 25px;
    }
    #botText2{
        margin-top: 50px;
        font-size: 12px;
    }
}
@media (max-width:280px){
    #botBox1{
        border: 0px solid white;
        width:250px;
        height: 180px;
        border-radius:0px;
    }
    #botBox2{
        width:250px;
        height: 180px;
        border-radius:0px;
    }
    #botText1{
        margin-top: 60px;
        font-size: 20px;
    }
    #botText2{
        margin-top: 50px;
        font-size: 9px;
    }
}