#defiBox{
    position: relative;
    background-color: #010313;
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 50px 0px;
}
#defiContent{
    width: 98%;
    margin: auto;
}
#defiBgVideo{
    width: 100%;
    border-radius: 30px;
    filter: blur(4px);
    position: relative;
}
#defiBgVideo2{
    display: none;
}
#defiText{
    border: 0px solid yellow;
    width: 98%;
    position: absolute;
    bottom: 15em;
    left: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#defiTextH1{
    border: 0px solid white;
    width: 35%;
}
#defiTexth1Tag{
    font-family: Roobert, sans-serif;
    color: #fff;
    font-size: 3em;
    line-height: 117%;
    font-weight: 400;
    text-transform: none;
}
#defiBoxes{
    border: 0px solid red;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    width: 50%;
    height: 11em;
    margin-top: 100px;
}
#defiBox1{
    background-color: #73dfa9;
    display: grid;
    gap: 20px;
}
#defiBox2{
    background-color: #73dfa9;
    display: grid;
    gap: 20px;
}
#defiBox1:hover,#defiBox2:hover{
    background-color: #010313;
    color: #73dfa9;
}
#defiButtons{
    border: 0px solid black;
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
#defiButtons button{
    width: 20%;
    height: 30px;
    border: none;
    border-radius: 20px;
    font-family: Roobert, sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
    background-color: #addcc4;
}
#defiPtag{
    border: 0px solid black;
    width: 90%;
    margin: auto;
    font-family: Ttfirsneue, sans-serif;
    font-size: 20px
}
@media (max-width:1300px){
    #defiText{
        bottom: 18em;
    }
}
@media (max-width:1030px){
    #defiText{
        bottom: 14em;
    }
    #defiTexth1Tag{
        font-size: 40px;
    }
}
@media (max-width:920px){
    #defiBox{
        height:120vh;
    }
    #defiBgVideo{
        display: none;
    }
    #defiBgVideo2{
        display: block;
        width: 100%;
        border-radius: 30px;
        filter: blur(4px);
        position: relative;
    }  
    #defiText{
        display: grid;
        gap: 150px;
        border: 0px solid white;
        width: 100%;
        bottom: 30em;
        left: 0em;
    }
      #defiTextH1{
        width: 90%;
        margin: auto;
        font-size: 25px;
      }
      #defiBoxes{
          width: 70%;
          display: grid;
          margin: auto;
          grid-template-columns: repeat(1,1fr);
      }
      #defiBox1{
        background-color: #73dfa9;
        display: grid;
        gap: 20px;
        height: 15em;
      }
    #defiBox2{
        background-color: #73dfa9;
        display: grid;
        gap: 20px;
        height: 15em;
    }
    #defiButtons button{
        width: 40%;
        height: 50px;
        font-size: 25px;
    }
    #defiPtag{
        border: 0px solid black;
        width: 90%;
        margin: auto;
        font-family: Ttfirsneue, sans-serif;
        font-size: 26px;
        font-weight: bold;
    }
}
@media (max-width:820px) and (min-width:750px){
    #defiBox{
        height:120vh;
    }
    #defiBgVideo{
        display: none;
    }
    #defiBgVideo2{
        display: block;
        width: 100%;
        border-radius: 30px;
        filter: blur(4px);
        position: relative;
    }  
    #defiText{
        display: grid;
        gap: 150px;
        border: 0px solid white;
        width: 100%;
        bottom: 30em;
        left: 0em;
    }
      #defiTextH1{
        width: 90%;
        margin: auto;
        font-size: 20px;
      }
      #defiBoxes{
          width: 80%;
          display: grid;
          margin: auto;
          grid-template-columns: repeat(1,1fr);
      }
      #defiBox1{
        background-color: #73dfa9;
        display: grid;
        gap: 20px;
        height: 15em;
      }
    #defiBox2{
        background-color: #73dfa9;
        display: grid;
        gap: 20px;
        height: 15em;
    }
    #defiButtons button{
        width: 40%;
        height: 50px;
        font-size: 25px;
    }
    #defiPtag{
        border: 0px solid black;
        width: 90%;
        margin: auto;
        font-family: Ttfirsneue, sans-serif;
        font-size: 26px;
        font-weight: bold;
    }
}
@media (max-width:550px){
    #defiBox{
        height: 210vh;
    }
    #defiTexth1Tag{
        font-size: 50px;
    }
    #defiButtons button{
        width: 30%;
        height: 40px;
        font-size: 20px;
    }
    #defiPtag{
        border: 0px solid black;
        width: 90%;
        margin: auto;
        font-family: Ttfirsneue, sans-serif;
        font-size: 20px;
        font-weight: bold;
    }
}
@media (max-width:480px) and (min-width:100px){
    #defiBox{
        height: 100vh;
    }
    #defiBgVideo{
        display: none;
    }
    #defiBgVideo2{
        display: block;
        width: 100%;
        border-radius: 30px;
        filter: blur(4px);
        position: relative;
    }
    #defiText{
        display: grid;
        gap: 150px;
        border: 0px solid white;
        width: 100%;
        bottom: 0em;
        left: 0em;
        height: fit-content;
    }
    #defiTextH1{
      width: 90%;
      margin: auto;
     
    }
    #defiTexth1Tag{
        font-size: 30px;
    }
    #defiBoxes{
        width: 90%;
        display: grid;
        margin: auto;
        grid-template-columns: repeat(1,1fr);
    }
    #defiButtons button{
        width: 30%;
        height: 40px;
        font-size: 17px;
    }
    #defiPtag{
        border: 0px solid black;
        width: 90%;
        margin: auto;
        font-family: Ttfirsneue, sans-serif;
        font-size: 17px;
        font-weight: bold;
    }
}
@media (max-width:424px){
    #defiBox{
        height: 80vh;
        border: 0px solid white;
    }
}
@media (max-width:280px){
    #defiBox{
        height:50vh;
        border: 0px solid white;
    }
    #defiText{
        top: 4em;
        bottom: 0em;
    }
    #defiTexth1Tag{
        font-size: 25px;
    }
    #defiBox1,#defiBox2{
        height: 170px;
    }
}